import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Terms from "../components/Terms";

let IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="terms and conditions" />
    <Terms />
  </Layout>
);

export default IndexPage;
