import React from "react";
import * as styles from "./index.module.scss";
import PageTitle from "../utils/PageTitle";
import { Link } from "gatsby";

export default function index() {
  return (
    <div>
      <section className={`${styles.section} page-section-top`}>
        <div className="section-container">
          <PageTitle title="terms of use" />
          <div className={styles.privacy}>
            <p>
              This Terms of Use Statement applies only to the website at
              www.vectr.co. This site is owned and operated by Vectr Ventures
              Limited (Vectr) and should not be confused with any other website
              whether or not the word Vectr appears as part of the domain name
              or branding. You are advised to check each page you visit on or
              from this site to determine whether you have moved onto a third
              party site.
            </p>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>SITE SECURITY</h5>
              <p>
                You are prohibited from violating, or attempting to violate, the
                security of this site. Any such violations may result in
                criminal and/or civil penalties against you. We will investigate
                any alleged or suspected violations and if a criminal violation
                is suspected, we will cooperate with law enforcement agencies in
                their investigations. Violations of the security of the site
                include without limitation:
              </p>
              <ul className={styles.list}>
                <li className={styles.item}>
                  Logging into or attempting to log into a server or account
                  that you are not authorized to access;
                </li>
                <li className={styles.item}>
                  Accessing data or taking any action to obtain data,
                  information or services not intended for you or your use;
                </li>
                <li className={styles.item}>
                  Attempting to probe, scan or test the vulnerability of any
                  system or network;
                </li>
                <li className={styles.item}>
                  Tampering, hacking, modifying or otherwise corrupting or
                  breaching security or authentication measures;
                </li>
                <li className={styles.item}>
                  Transmitting material that contains viruses, Trojan horses,
                  worms, time bombs, cancelbots, corrupted files or other
                  computer programming routines or engines or engage in conduct
                  that could damage, disrupt or otherwise impair or interfere
                  with a computer’s functionality or the operation of the site.
                </li>
              </ul>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>
                OTHER PROHIBITED ACTIVITY
              </h5>
              <p>In using this site, you must not:</p>
              <ul className={styles.list}>
                <li className={styles.item}>
                  Post, send or otherwise transmit to or through the site any
                  unlawful, infringing, harmful, harassing, defamatory,
                  threatening, vulgar, sexually explicit, hateful or otherwise
                  objectionable material of any kind, any material that exploits
                  children or is invasive of or in breach of another person’s
                  privacy or other rights or any material that Vectr in its sole
                  discretion does not wish posted or transmitted on the site;
                </li>
                <li className={styles.item}>
                  Defame, abuse, harass, stalk, threaten or otherwise violate
                  the legal rights (such as rights of privacy and publicity) of
                  others;
                </li>
                <li className={styles.item}>
                  Upload or otherwise make available, files that contain images,
                  software or other material protected by intellectual property
                  laws, including without limitation copyright or trademark laws
                  and rights of publicity and privacy unless you own or control
                  the rights thereto or have received all necessary
                  authorizations to do the same;
                </li>
                <li className={styles.item}>
                  Misrepresent your identity or affiliation in any way;
                </li>
                <li className={styles.item}>
                  Engage in deceptive online marketing;
                </li>
                <li className={styles.item}>
                  Violate any applicable laws or regulations; or
                </li>
                <li className={styles.item}>
                  Assist or permit any persons in engaging in any of the
                  activities described above.
                </li>
              </ul>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>USER SUBMISSIONS</h5>
              <p>
                You must exercise caution, good sense and sound judgment in
                using the site. You are responsible for any material you place
                on or transmit to or through the site. You agree, represent and
                warrant that any information you post to or transmit through the
                site is truthful, accurate, not misleading and offered in good
                faith, and that you have the right to post or transmit such
                information. Such information (including without limitation,
                data, text, software, graphics or any other materials
                whatsoever), whether publicly posted or privately transmitted,
                is your sole responsibility.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>UNSOLICITED IDEAS</h5>
              <p>
                Vectr does not accept or consider unsolicited ideas, including
                ideas for new promotions, products, technologies, or processes.
                You must not transmit any material to or through this site that
                you consider to be confidential or proprietary. Any material
                that you transmit to or through this site will be considered
                non-confidential and non-proprietary. This policy serves to
                avoid potential misunderstandings or disputes regarding
                ownership of ideas. Except as expressly provided in the Privacy
                Statement or separate written agreement between you and Vectr,
                you give Vectr an unrestricted, irrevocable, worldwide,
                royalty-free license to use, reproduce, display, publicly
                perform, transit and distribute such information. You further
                agree that Vectr has the right to use, without any payment or
                accounting to you or others, any concepts, know-how or ideas
                that you (and those who act on your behalf) transmit to or
                through this site.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>COPYRIGHT</h5>
              <p>
                The material made available at this site is protected by
                copyright. No material from this site may be copied, reproduced,
                republished, uploaded, posted, transmitted, or distributed in
                any way without written permission of the copyright owner,
                except that you may download one copy of the materials on any
                single computer and produce one printed copy for your personal,
                noncommercial use only, provided you keep intact all copyright
                and other proprietary notices. Modification of the materials or
                use of the materials for any other purpose is a violation of
                Vectr’s copyright and other proprietary rights. Permission for
                all other uses of materials contained herein, including
                reproducing and distributing multiple copies, or linking to any
                page at this site except the “home page” (http://www.vectr.co),
                must be obtained from Vectr in advance. Requests for such
                authorization should be sent via email. For purposes of this
                Agreement, the use of any such material on any other website or
                networked computer environment is prohibited. All design rights,
                databases and compilation and other intellectual property
                rights, in each case whether registered or unregistered, and
                related goodwill are proprietary to Vectr.
              </p>
              <p>
                In the event you download software from the site, the software,
                including any files, images incorporated in or generated by the
                software, and data accompanying the software (collectively, the
                “Software”) are licensed to you by Vectr. Vectr does not
                transfer title to the Software to you. You may own the medium on
                which the Software is recorded, but Vectr retains full and
                complete title to the Software, and all intellectual property
                rights therein. You may not redistribute, sell, decompile,
                reverse engineer, disassemble or otherwise reduce the Software
                to a human-perceivable form, except to the extent permitted by
                applicable law and on giving Vectr prior written notice of such
                activities.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>TRADEMARKS</h5>
              <p>
                All trademarks, service marks, logos and trade names, whether
                registered or unregistered, are proprietary to Vectr or to other
                companies where so indicated. You may not reproduce, download or
                otherwise use any such trademarks, service marks, logos or trade
                names without the prior written consent of the appropriate owner
                thereof.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>LINKS TO OTHER WEBSITES</h5>
              <p>
                As you view this site, you will notice links to other non-Vectr
                websites. These links are for convenience only. Certain of these
                linked websites may make use of Vectr proprietary intellectual
                property rights (such as trademarks, service marks, logos and
                trade names) under license from Vectr. Vectr is not responsible
                for the availability or content of these sites or for any
                viruses or other damaging elements encountered in linking to a
                third party website. In addition, providing links to these sites
                should not be interpreted as endorsement or approval by Vectr of
                the organizations sponsoring the sites or their products or
                services.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>JURISDICTIONAL ISSUES</h5>
              <p>
                This site is controlled and operated by Vectr from its offices
                in Sheung Wan, Hong Kong SAR. Vectr makes no representation that
                materials in the site are appropriate or available for use in
                other locations. Those who choose to access this site from other
                locations do so on their own initiative and are responsible for
                compliance with local laws, if and to the extent local laws are
                applicable. Access to this site from jurisdictions where the
                contents of this site are illegal or penalized is prohibited.
                Software from this site is further subject to Hong Kong export
                controls. No information or software from this site may be
                downloaded or otherwise or re-exported (i) into or to a national
                or resident of any country to which Hong Kong embargoes or
                sanctions goods, services or technology. By downloading or using
                the Software, you represent and warrant that you are not located
                in, under the control of, or a national or resident of any such
                country or on any such list.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>TERMINATION</h5>
              <p>
                This agreement is effective until terminated by either party.
                You may terminate this agreement at any time by destroying all
                materials obtained from this site and all related documentation
                and all copies and installations thereof, whether made under the
                terms of this agreement or otherwise. This agreement will
                terminate immediately without notice from Vectr if in Vectr’s
                sole discretion you fail to comply with any term or provision of
                this agreement. Upon termination, you must destroy all materials
                obtained from this site and all copies thereof, whether made
                under the terms of this agreement or otherwise. The provisions
                of this Terms of Use Statement concerning site security,
                prohibited activity, copyrights, trademarks, user submissions,
                disclaimer, limitation of liability, indemnity, governing law
                and jurisdiction shall survive any termination of this
                agreement.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>DISCLAIMER</h5>
              <p>
                The materials in this site are provided “as is” and without
                warranties of any kind either express or implied. To the fullest
                extent permissible pursuant to applicable law, Vectr disclaims
                all warranties, express or implied, including, but not limited
                to, implied warranties of merchantability and fitness for a
                particular purpose. Vectr does not warrant that the functions
                contained in the materials will be uninterrupted or error-free,
                that defects will be corrected, or that this site or the server
                that makes it available are free of viruses or other harmful
                components. Vectr does not make any representations or
                warranties regarding the use or the results of the use of the
                materials in this site in terms of their correctness, accuracy,
                reliability, or otherwise. Applicable law may not allow the
                exclusion of implied warranties, so the above exclusion may not
                apply to you.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>LIMITATION OF LIABILITY</h5>
              <p>
                To the extent permitted under applicable law, under no
                circumstances, including, but not limited to, negligence, shall
                Vectr be liable for any compensatory, punitive, special or
                consequential damages that result from the use of, or the
                inability to use, the materials in this site, even if Vectr or a
                Vectr authorized representative has been advised of the
                possibility of such damages. Applicable law may not allow the
                limitation or exclusion of liability or incidental or
                consequential damages, and so to that extent the above
                limitation or exclusion may not apply to you. In no event shall
                Vectr’s total liability to you for all damages, losses, and
                causes of action (whether in contract, tort (including, but not
                limited to, negligence) or otherwise) exceed the amount paid by
                you to Vectr, if any, for using this site.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>INDEMNITY</h5>
              <p>
                You agree to indemnify, defend and hold Vectr, its affiliates
                and any of its and their directors, employees, agents and
                contractors harmless from and against any and all claims,
                damages, losses, costs (including without limitation reasonable
                attorneys’ fees) or other expenses that arise directly or
                indirectly out of or from (i) your breach of this agreement;
                and/or (ii) your activities in connection with this site.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>
                DISCLOSURE, FORWARD-LOOKING STATEMENTS
              </h5>
              <p>
                This Web site contains forward-looking statements relating to
                Vectr’s operations that are based on management’s current
                expectations, estimates and projections about the investment,
                tech and other related industries. Words such as “anticipates,”
                “expects,” “intends,” “plans,” “targets,” “forecasts,”
                “projects,” “believes,” “seeks,” “schedules,” “estimates,”
                “may,” “could,” “budgets,” “outlook” and similar expressions are
                intended to identify such forward-looking statements. These
                statements are not guarantees of future performance and are
                subject to certain risks, uncertainties and other factors, many
                of which are beyond the company’s control and are difficult to
                predict. Therefore, actual outcomes and results may differ
                materially from what is expressed or forecasted in such
                forward-looking statements. The reader should not place undue
                reliance on these forward-looking statements, which speak only
                as of the date of this report. Unless legally required, Vectr
                undertakes no obligation to update publicly any forward-looking
                statements, whether as a result of new information, future
                events or otherwise.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>OTHER</h5>
              <p>
                As used throughout this site, the term “Vectr” and such terms as
                “the company,” “the corporation,” “our,” “we” and “us” may refer
                to one or more or all of Vectr Ventures Limited’s portfolio
                companies. All of these terms are used for convenience only and
                are not intended as a precise designation of any of the separate
                companies, each of which manages its own affairs.
              </p>
              <p>
                This agreement shall be governed by and construed in accordance
                with the laws of Hong Kong SAR, without giving effect to any
                principles of conflicts of law. If any provision of this
                agreement shall be unlawful, void, or for any reason
                unenforceable, then that provision shall be deemed severable
                from this agreement and shall not affect the validity and
                enforceability of any remaining provisions. This is the entire
                agreement between the parties relating to the subject matter
                herein and shall not be modified except in writing.
              </p>
            </div>
            <div className={styles.paragraph}>
              <h5 className={styles.paragraphTitle}>
                CHANGES TO TERMS OF USE STATEMENTS
              </h5>
              <p>
                Vectr may revise this Terms of Use Statement at any time without
                notice. Although Vectr will endeavor to highlight any changes to
                this Statement, you should revisit this site periodically to
                make sure you are aware of the most recent terms, because they
                will be binding on you. Your use of the site after such changes
                constitutes your agreement to such changes.
              </p>
            </div>
            <div className={styles.paragraph}>
              <p>
                <strong>
                  If you have any questions or complaints regarding this Terms
                  of Use Statement, please <Link to="contact">contact us </Link>
                  and we will endeavor to respond to you promptly.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
